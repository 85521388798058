@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
:root {
    /* --bg-color: #f4f2e2; */
    --bg-color: #f6f6f6;
    --bg-dark: #141033;
    /* --primary-color: #9bd8f0;
    --secondary-color: #1ebfc2;
    --accent-color: #000080; */
    --btn-green: #3c8829;
    --btn-red: #ff0000;
    --btn-muted: #606c80;
    --box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    --box-shadow-topbar: 5px 5px 23px 10px rgba(0, 0, 0, 0.06);
    --border-radius: 15px;
    --text-white: white;
    --text-black: black;
    --text-grey: #606c80;
    --topbar-height: 106px;
    --sidebar-width: 146px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: var(--bg-dark);
}

*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px var(--themeColor);
    border-radius: 10px;
}